import React, { useEffect, useRef, useState } from 'react';
import styles from './table-card.module.less';
import classNames from 'classnames';
import {
  TypographyComponent,
  TypographySize,
  TypographyVariant,
  TypographyWeight,
} from '@vaa-component-lib/component.atom.typography';
import getIcon from '../icons';
import {
  ButtonColour,
  ButtonComponent,
  ButtonSize,
  ButtonType,
} from '@vaa-component-lib/component.atom.button';
import { IconSize } from '@vaa-component-lib/component.atom.icon';
import HTMLRenderer, { Element } from '../html-renderer/html-renderer.component';

type TableItem = {
  icon: string;
  text: string;
  label: string;
};

type TableButton = {
  text: string;
  href: string;
};

interface TableCardProps {
  id: string;
  title: string;
  subtitle: string;
  labelPosition: string;
  selectTheme: string;
  showIcons: boolean;
  firstMultiField: Record<string, TableItem>;
  secondMultiField: Record<string, TableItem>;
  button?: TableButton;
  selectColour: string;
  target: string;
}

const TableCard: React.FC<TableCardProps> = ({
  id,
  title,
  subtitle,
  labelPosition,
  selectTheme,
  showIcons,
  firstMultiField = {},
  secondMultiField = {},
  button = { text: '', href: '' },
  selectColour,
  target,
}) => {
  const tableCardClasses = classNames(
    styles['table-card'],
    styles[`table-card__${selectTheme}`],
    styles[`table-card__color-${selectColour}`],
    styles[`table-card__label-position-${labelPosition}`]
  );

  const firstListRefs = useRef<(HTMLLIElement | null)[]>([]);
  const secondListRefs = useRef<(HTMLLIElement | null)[]>([]);
  const [isFirstListOverflow, setIsFirstListOverflow] = useState(false);
  const [isSecondListOverflow, setIsSecondListOverflow] = useState(false);

  const renderIcon = (iconText: string, isFirstField: boolean) => {
    if (!iconText) return null;

    const IconComponent = getIcon(iconText);
    const iconSize = isFirstField ? IconSize.Sml : IconSize.Xsm;

    return IconComponent ? <IconComponent size={iconSize} /> : null;
  };

  const renderListItems = (
    items: Record<string, TableItem>,
    isFirstField: boolean,
    refs: React.MutableRefObject<(HTMLLIElement | null)[]>,
    isOverflow: boolean
  ) => {
    const textSize = isFirstField ? TypographySize.Sml : TypographySize.Xsm;
    const labelSize = isFirstField ? TypographySize.Sml : TypographySize.Xxs;

    return Object.entries(items).map(([key, item], index) => (
      <li
        key={key}
        className={classNames(styles['table-card__list-item'], {
          [styles['table-card__list-item--overflow']]: isOverflow,
        })}
        ref={(el) => {
          refs.current[index] = el;
        }}
      >
        {showIcons && renderIcon(item.icon, isFirstField)}

        {item.text && (
          <TypographyComponent
            element="div"
            variant={TypographyVariant.Body}
            size={textSize}
            weight={TypographyWeight.Regular}
          >
            <HTMLRenderer htmlString={item.text} element={Element.DIV}/>
          </TypographyComponent>
        )}

        {item.label && (
          <TypographyComponent
            element="label"
            variant={TypographyVariant.Caption}
            size={labelSize}
            weight={TypographyWeight.Regular}
          >
            {item.label}
          </TypographyComponent>
        )}
      </li>
    ));
  };

  useEffect(() => {
    const firstListHeights = firstListRefs.current.map(
      (item) => item?.offsetHeight || 0
    );
    const secondListHeights = secondListRefs.current.map(
      (item) => item?.offsetHeight || 0
    );

    setIsFirstListOverflow(firstListHeights.some((height) => height > 22));
    setIsSecondListOverflow(secondListHeights.some((height) => height > 22));
  }, [firstMultiField, secondMultiField]);

  const hrefTarget = target === 'new' ? '_blank' : '_self';

  return (
    <div id={id} className={`${tableCardClasses}`} tabIndex={0}>
      <div className={styles['table-card__color-band']}></div>
      <div className={styles['table-card__inner']}>
        <div className={styles['table-card__heading']}>
          {title && (
            <TypographyComponent
              element="h2"
              variant={TypographyVariant.Heading}
              size={TypographySize.Mdm}
              weight={TypographyWeight.Regular}
            >
              {title}
            </TypographyComponent>
          )}
        </div>

        <ul className={`${styles['table-card__list']} ${styles['table-card__list--main']}`}>
          {renderListItems(
            firstMultiField,
            true,
            firstListRefs,
            isFirstListOverflow
          )}
        </ul>

        <div className={styles['table-card__subheading']}>
          {subtitle && (
            <TypographyComponent
              element="h3"
              variant={TypographyVariant.Heading}
              size={TypographySize.Xsm}
              weight={TypographyWeight.Medium}
            >
              {subtitle}
            </TypographyComponent>
          )}
        </div>

        <ul className={`${styles['table-card__list']} ${styles['table-card__list--sub']}`}>
          {renderListItems(
            secondMultiField,
            false,
            secondListRefs,
            isSecondListOverflow
          )}
        </ul>

        {button.text && (
          <ButtonComponent
            size={ButtonSize.Small}
            colour={ButtonColour.Brand}
            href={button.href}
            type={ButtonType.Link}
            target={hrefTarget}
          >
            {button.text}
          </ButtonComponent>
        )}
      </div>
    </div>
  );
};

export default TableCard;
