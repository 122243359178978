import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { createContext, useEffect, useState } from 'react';
import 'styles/globals.less';
import { subscribe, unsubscribe } from '../src/utils/events';
import { usePathname } from 'next/navigation';
import getLocaleSubdomain from 'subdomains';

const defaultContext = {
  theme: 'light',
  switchThemeCallback: (theme: string) => { },
  accordionAnchor: '',
};

export const VAAContext = createContext(defaultContext);

function MyApp({ Component, pageProps }: AppProps) {
  const pathname = usePathname();
  const router = useRouter();

  const switchTheme = (theme: string) => {
    setAppContext({ ...appContext, theme });
  };

  const [appContext, setAppContext] = useState({
    theme: 'light',
    switchThemeCallback: switchTheme,
    accordionAnchor: '',
  });

  useEffect(() => {
    if (typeof window !== 'undefined' && router.asPath.includes('#')) {
      const accordionAnchor = router.asPath.split('#')[1];
      if (accordionAnchor) {
        setAppContext({ ...appContext, accordionAnchor });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  useEffect(() => {
    subscribe('VAA_LOCALE_CHANGE', (e: any) => {
      // console.log('VAA_LOCALE_CHANGE fired', e);

      const selectedLocale = e.detail.data ?? null;
      if (selectedLocale) {
        const subdomain = getLocaleSubdomain(selectedLocale);
        if (subdomain) {
          window.location = subdomain as any;
        } else {
          router.push(pathname, pathname, {
            locale: selectedLocale,
          });
        }
      }
    });
    return () => unsubscribe('VAA_LOCALE_CHANGE', () => { });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VAAContext.Provider value={appContext}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
        />
      </Head>
      <Component {...pageProps} />
    </VAAContext.Provider>
  );
}

export default MyApp;
