import { ESIData } from 'src/components/page-esi-wrapper/page-esi-wrapper.component';
import { ValidationResult, ValidationOutcomes } from '.';
import { isObjectEmpty } from '../utils/aem-utils/utils';

const getSearchESI = (
  version: string,
  destination: string | null = null,
  bookingType: string | null = null
): string => {
  let esiString: string = '';
  const esiData = ESIData(false, destination, bookingType);
  switch (version) {
    case 'V1':
      esiString = esiData.VHOLS.search.esi;
      break;
    case 'V2':
      esiString = esiData.VAA.search.esi;
      break;
    default:
      esiString = '';
      break;
  }
  return esiString;
};

const ValidateSearchPanelComponentProps = (props: any): ValidationResult => {
  let vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
  };

  if (isObjectEmpty(props)) {
    vr.messages.push('Search Panel is empty');
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  if (!props.hasOwnProperty('version')) {
    vr.messages.push('Search Panel is missing version property');
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  vr.componentProps = props;
  const { version, destination, bookingType } = props;

  let searchESI = getSearchESI(version, destination, bookingType);

  if (searchESI === '') {
    vr.messages.push('Search Panel version is invalid');
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  // V1 - Virgin Holidays
  if (props['version'] === 'V1') {
    vr.componentProps['displayButton'] = true;
  }

  // V2 - Virgin Atlantic
  if (props['version'] === 'V2') {
    if (props.hasOwnProperty('open') && props['open'] === false) {
      searchESI = searchESI.replace('open=true', 'open=false');
    }
    if (props.hasOwnProperty('closable') && props['closable'] === true) {
      searchESI = searchESI.replace('closable=false', 'closable=true');
    }

    vr.componentProps['displayButton'] = false;
  }

  vr.componentProps['ESIString'] = searchESI;

  vr.result = ValidationOutcomes.SUCCESS;
  return vr;
};

export default ValidateSearchPanelComponentProps;
