import { objectHasFields } from 'src/utils/aem-utils/utils';
import { ValidationResult, ValidationOutcomes } from '.';
import isIconPanelPanelPropValid from './icon-panel-panel-property-validator';
import { checkAndAddProps } from 'src/utils';

const ValidateIconPanelComponentProps = (props: any): ValidationResult => {
  const vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
    componentProps: null,
  };

  if (
    !objectHasFields(props, ['items']) ||
    !Array.isArray(props.items) ||
    props.items.length === 0
  ) {
    vr.messages.push('Icon Panel is missing IconPanelPanel items[]');
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  // IconPanel has children panels, check if they are valid
  let panels: any = [];
  props.items.forEach((panel: any, index: number) => {
    const panelValidationResult = isIconPanelPanelPropValid(panel);

    if (panelValidationResult.result === ValidationOutcomes.FAILURE) {
      // single panel validation failed, IconPanel may be valid
      vr.result = ValidationOutcomes.WARNING;
      vr.messages = vr.messages.concat(panelValidationResult.messages);
      vr.messages.push(`Invalid panel data at item[${index}], panel deleted`);
    } else if (panelValidationResult.result === ValidationOutcomes.WARNING) {
      vr.result = ValidationOutcomes.WARNING;
      vr.messages = vr.messages.concat(panelValidationResult.messages);
      vr.messages.push(`Warning panel data at item[${index}], using the panel`);
      panels.push(panelValidationResult.componentProps);
    } else {
      // panel is valid
      if (vr.result !== ValidationOutcomes.WARNING)
        vr.result = ValidationOutcomes.SUCCESS;
      panels.push(panelValidationResult.componentProps);
    }
  });

  if (panels.length === 0) {
    // no valid iconpanelpanels found, container is invalid
    vr.result = ValidationOutcomes.FAILURE;
    vr.messages.push('No valid panels found, IconPanel is invalid');
  } else {
    // at least one valid panel found, IconPanel is valid
    vr.componentProps = { items: panels };

    checkAndAddProps(props, vr.componentProps, [
      'theme',
      'panelSize',
      'iconPosition',
    ]);
  }

  return vr;
};

export default ValidateIconPanelComponentProps;
