import { objectHasFields } from 'src/utils/aem-utils/utils';
import { ValidationResult, ValidationOutcomes } from '.';

const isTagPropValid = (tagProps: any): ValidationResult => {
  const vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
    componentProps: null,
  };

  if (!objectHasFields(tagProps, ['label'])) {
    vr.result = ValidationOutcomes.FAILURE;
    vr.messages.push('Tag is missing label');
    return vr;
  }

  vr.result = ValidationOutcomes.SUCCESS;
  vr.componentProps = tagProps;
  return vr;
};

export default isTagPropValid;
