import { objectHasFields } from 'src/utils/aem-utils/utils';
import { ValidationOutcomes, ValidationResult } from '.';

const ValidateHotelsCarouselComponentProps = (props: any): ValidationResult => {

  if (!props) {
    return {
      result: ValidationOutcomes.FAILURE,
      messages: ['No props were passed to Hotels Carousel component'],
    };
  }

  let vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
  };


  if (!objectHasFields(props, ['mimId'])) {
    vr.messages.push('Hotels Carousel is missing mimId');
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  vr.result = ValidationOutcomes.SUCCESS;
  vr.componentProps = props;
  return vr;
};

export default ValidateHotelsCarouselComponentProps;
