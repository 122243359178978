import { objectHasFields } from 'src/utils/aem-utils/utils';
import { ValidationOutcomes, ValidationResult } from '.';
import isBadgePropValid from './badge-property-validator';
import isButtonPropValid from './button-property-validator';
import isImagePropValid from './image-property-validator';

const ValidateContentCardComponentProps = (props: any): ValidationResult => {
  let vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
  };

  if (!objectHasFields(props, ['title', 'image'])) {
    vr.messages.push('Content card is missing title or image');
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  // check for valid image
  const imageValidationResult = isImagePropValid(props.image);

  if (imageValidationResult.result === ValidationOutcomes.FAILURE) {
    vr.messages = vr.messages.concat(imageValidationResult.messages);
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  } else if (imageValidationResult.result === ValidationOutcomes.WARNING) {
    vr.result = ValidationOutcomes.WARNING;
    vr.messages = vr.messages.concat(imageValidationResult.messages);
    props.image = imageValidationResult.componentProps;
  } else {
    vr.result = ValidationOutcomes.SUCCESS;
    vr.messages = vr.messages.concat(imageValidationResult.messages);
    props.image = imageValidationResult.componentProps;
  }

  // if it has badge check for a valid badge data
  if (props.hasOwnProperty('badge')) {
    const badgeValidationResult = isBadgePropValid(props.badge);

    if (badgeValidationResult.result === ValidationOutcomes.FAILURE) {
      vr.result = ValidationOutcomes.WARNING;
      vr.messages = vr.messages.concat(badgeValidationResult.messages);
      vr.messages.push('Invalid badge data, badge deleted');
      delete props.badge;
    }
  }

  // if it has button check for a valid button data
  if (props.hasOwnProperty('button')) {
    const buttonValidationResult = isButtonPropValid(props.button);

    if (buttonValidationResult.result === ValidationOutcomes.FAILURE) {
      vr.result = ValidationOutcomes.WARNING;
      vr.messages = vr.messages.concat(buttonValidationResult.messages);
      vr.messages.push('Invalid button data, button deleted');
      delete props.button;
    }
  }

  vr.componentProps = props;
  return vr;
};

export default ValidateContentCardComponentProps;
