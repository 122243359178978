import { ValidationResult, ValidationOutcomes } from '.';
import ValidateContentCardComponentProps from './content-card-component-validator';
import ValidateTableCardProps from './table-card-component-validator';

const ValidateContentCardContainerComponentProps = (props: any): ValidationResult => {
  let vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
  };

  if (!props.hasOwnProperty('cards') || props.cards.length <= 0) {
    vr.messages.push('Content card container is missing cards[] array');
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  const cards: any[] = [];
  props.cards.forEach((cardProps: any, i: number) => {
    if (
      !cardProps.hasOwnProperty('sling:resourceType') ||
      !['content-card', 'table-card'].includes(
        cardProps['sling:resourceType'].split('/').pop()
      )
    ) {
      vr.result = ValidationOutcomes.WARNING;
      vr.messages.push(
        `Content card at index ${i} is missing sling:resourceType, invalid type`
      );
      return;
    }


    let cardValidationResult: ValidationResult = {
      result: ValidationOutcomes.UNKNOWN,
      messages: [],
    };

     // first get card type
     const cardType = cardProps['sling:resourceType'].split('/').pop();
     switch (cardType) {
       case 'content-card':
         cardValidationResult = ValidateContentCardComponentProps(cardProps);
         break;
       case 'table-card':
         cardValidationResult = ValidateTableCardProps(cardProps);
         break;
       default:
         break;
     }

    if (cardValidationResult.result === ValidationOutcomes.FAILURE) {
      // single card validation failed, container may be valid
      vr.result = ValidationOutcomes.WARNING;
      vr.messages = vr.messages.concat(cardValidationResult.messages);
      vr.messages.push(`Invalid card data at item${i}, card deleted`);
    } else if (cardValidationResult.result === ValidationOutcomes.WARNING) {
      // single card validation failed, container may be valid
      vr.result = ValidationOutcomes.WARNING;
      vr.messages = vr.messages.concat(cardValidationResult.messages);
      vr.messages.push(`Warning at card data at item${i}, using the card`);
      cards.push({ componentProps: cardValidationResult.componentProps });
    } else {
      // card is valid
      if (vr.result !== ValidationOutcomes.WARNING)
        vr.result = ValidationOutcomes.SUCCESS;
      cards.push({ componentProps: cardValidationResult.componentProps });
    }
  });

  if (cards.length === 0) {
    // no valid cards found, container is invalid
    vr.result = ValidationOutcomes.FAILURE;
    vr.messages.push('No valid cards found, container is invalid');
  } else {
    // at least one valid card found, container is valid
    vr.componentProps = { cards };
  }

  return vr;
};

export default ValidateContentCardContainerComponentProps;
