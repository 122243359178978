import * as iconsLib from '@vaa-component-lib/component.atom.icons';
const icons: any = { ...iconsLib };

const formatIconName = (iconIdentifier: string) =>
  iconIdentifier.split('-').map((word) =>
    word.charAt(0).toUpperCase() + word.slice(1)).join('').concat('Component');

const getIcon = (iconIdentifier: string) =>
  Object.keys(icons).includes(formatIconName(iconIdentifier)) ?
    icons[formatIconName(iconIdentifier)] : null;

export const IconsSize = icons.IconsSize;
export default getIcon;