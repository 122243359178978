import { ValidationResult, ValidationOutcomes } from '.';
import { isObjectEmpty } from '../utils/aem-utils/utils';

const ValidateHeadingComponentProps = (props: any): ValidationResult => {
  let vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
  };

  if (isObjectEmpty(props)) {
    vr.messages.push('Heading is empty');
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  vr.componentProps = props;
  vr.result = ValidationOutcomes.SUCCESS;
  return vr;
};

export default ValidateHeadingComponentProps;
