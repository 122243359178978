import { convertToAbsoluteImageUrl } from 'src/utils';
import { ValidationOutcomes, ValidationResult } from '.';

const isImagePropValid = (imageProps: any): ValidationResult => {
  const vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
    componentProps: null,
  };

  if (!imageProps.hasOwnProperty('url')) {
    vr.result = ValidationOutcomes.FAILURE;
    vr.messages.push('Image is missing url');
    return vr;
  }

  imageProps.url = imageProps.url.trim().replaceAll(' ', '%20');
  imageProps.url = convertToAbsoluteImageUrl(imageProps.url);

  if (!imageProps.hasOwnProperty('alt')) {
    vr.result = ValidationOutcomes.WARNING;
    vr.messages.push('Image is missing alt text');
    vr.componentProps = imageProps;
    return vr;
  }

  vr.result = ValidationOutcomes.SUCCESS;
  vr.componentProps = imageProps;
  return vr;
};

export default isImagePropValid;
