import { objectHasFields } from 'src/utils/aem-utils/utils';
import { ValidationResult, ValidationOutcomes } from '.';
import isBadgePropValid from './badge-property-validator';
import isImagePropValid from './image-property-validator';
import isTagPropValid from './tag-property-validator';
import { convertToAbsoluteLinkUrl } from 'src/utils';

const ValidateGalleryCardComponentProps = (props: any): ValidationResult => {
  // validates the destination cards
  const vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
    componentProps: null,
  };

  if (!objectHasFields(props, ['title', 'link', 'image'])) {
    vr.messages.push('Content card is missing title, link or image');
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  // check for valid image
  const imageValidationResult = isImagePropValid(props.image);

  if (imageValidationResult.result === ValidationOutcomes.FAILURE) {
    vr.messages = vr.messages.concat(imageValidationResult.messages);
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  } else if (imageValidationResult.result === ValidationOutcomes.WARNING) {
    vr.result = ValidationOutcomes.WARNING;
    vr.messages = vr.messages.concat(imageValidationResult.messages);
    props.image = imageValidationResult.componentProps;
  } else {
    vr.result = ValidationOutcomes.SUCCESS;
    vr.messages = vr.messages.concat(imageValidationResult.messages);
    props.image = imageValidationResult.componentProps;
  }

  // if it has badge check for a valid badge data
  if (props.hasOwnProperty('badge')) {
    const badgeValidationResult = isBadgePropValid(props.badge);

    if (badgeValidationResult.result === ValidationOutcomes.FAILURE) {
      vr.result = ValidationOutcomes.WARNING;
      vr.messages = vr.messages.concat(badgeValidationResult.messages);
      vr.messages.push('Invalid badge data, badge deleted');
      delete props.badge;
    }
  }

  // if it has tag check for a valid tag data
  if (props.hasOwnProperty('tag')) {
    const tagValidationResult = isTagPropValid(props.tag);

    if (tagValidationResult.result === ValidationOutcomes.FAILURE) {
      vr.result = ValidationOutcomes.WARNING;
      vr.messages = vr.messages.concat(tagValidationResult.messages);
      vr.messages.push('Invalid tag data, tag deleted');
      delete props.tag;
    }
  }

  // if it has relative link, convert to absolute
  if (props.hasOwnProperty('link'))
    props.link = convertToAbsoluteLinkUrl(props.link);

  vr.componentProps = props;
  return vr;
};

export default ValidateGalleryCardComponentProps;
