import { objectHasFields } from 'src/utils/aem-utils/utils';
import { ValidationOutcomes, ValidationResult } from '.';
import isButtonPropValid from './button-property-validator';
import isImagePropValid from './image-property-validator';

const ValidateTailfinHeroBannerContainerComponentProps = (props: any): ValidationResult => {
  let vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
  };

  if (!objectHasFields(props, ['heading', 'description', 'image'])) {
    vr.messages.push('TailfinHeroBanner is missing title, decription or image');
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  // check for valid image
  const imageValidationResult = isImagePropValid(props.image);

  if (imageValidationResult.result === ValidationOutcomes.FAILURE) {
    vr.messages = vr.messages.concat(imageValidationResult.messages);
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  } else if (imageValidationResult.result === ValidationOutcomes.WARNING) {
    vr.result = ValidationOutcomes.WARNING;
    vr.messages = vr.messages.concat(imageValidationResult.messages);
    props.image = imageValidationResult.componentProps;
  } else {
    vr.result = ValidationOutcomes.SUCCESS;
    vr.messages = vr.messages.concat(imageValidationResult.messages);
    props.image = imageValidationResult.componentProps;
  }

  // if it has button check for a valid buttons data - it's an array
  if (props.hasOwnProperty('buttons') && props.buttons.length > 0) {
    props.buttons.forEach((button: any, index: number, o: any) => {
      const buttonValidationResult = isButtonPropValid(button);

      if (buttonValidationResult.result === ValidationOutcomes.FAILURE) {
        vr.result = ValidationOutcomes.WARNING;
        vr.messages = vr.messages.concat(buttonValidationResult.messages);
        vr.messages.push('Invalid button data, button deleted');
        props.buttons.splice(index, 1);
      }
    });
  }

  vr.componentProps = props;
  return vr;
};

export default ValidateTailfinHeroBannerContainerComponentProps;
