import { objectHasFields } from 'src/utils/aem-utils/utils';
import { ValidationResult, ValidationOutcomes } from '.';
import ValidateAccordionItemProps from './accordion-item-component-validator';

export default function ValidateAccordionProps(
  accordionProps: any
): ValidationResult {
  const vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
    componentProps: null,
  };

  if (
    !objectHasFields(accordionProps, ['items']) ||
    !Array.isArray(accordionProps.items) ||
    accordionProps.items.length === 0
  ) {
    vr.messages.push('Accordion is missing AccordionItems[]');
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  // Accordion has children items, check if they are valid
  accordionProps.items.forEach((accordionItem: any, itemIndex: number) => {
    // Use correct props for validation
    accordionItem.index = itemIndex;

    const accordionItemValidationResult =
      ValidateAccordionItemProps(accordionItem);

    if (accordionItemValidationResult.result !== ValidationOutcomes.SUCCESS) {
      console.error('AccordionItem failed validation');
    }
  });

  vr.componentProps = accordionProps;
  vr.result = ValidationOutcomes.SUCCESS;
  return vr;
}
