import { ValidationResult, ValidationOutcomes } from '.';
import { isObjectEmpty } from '../utils/aem-utils/utils';

const ValidateClimateChartComponentProps = (props: any): ValidationResult => {
    let vr: ValidationResult = {
        result: ValidationOutcomes.UNKNOWN,
        messages: [],
    };

    if (isObjectEmpty(props)) {
        vr.messages.push('Climate Chart Component props are empty');
        vr.result = ValidationOutcomes.FAILURE;
        return vr;
    }

    if (!props.hasOwnProperty('rainfall')) {
        vr.messages.push('Rainfall is missing from Climate Chart Component props');
        vr.result = ValidationOutcomes.FAILURE;
    }

    if (!props.hasOwnProperty('temperature')) {
        vr.messages.push('Temperature is missing from Climate Chart Component props');
        vr.result = ValidationOutcomes.FAILURE;
    }

    if (!props.hasOwnProperty('chartKey')) {
        vr.messages.push('Chart Key is missing from Climate Chart Component props');
        vr.result = ValidationOutcomes.FAILURE;
    }

    vr.componentProps = props;
    vr.result = ValidationOutcomes.SUCCESS;
    return vr;
};

export default ValidateClimateChartComponentProps;
