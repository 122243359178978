import {
  stripPath,
  validateComponentProps,
  getColumnClassInfo,
  cleanHeadlessData,
  isObjectEmpty,
} from './utils';
import { ValidationOutcomes, ValidationResult } from 'src/validation';

let flatComponentsArray: any[] = [];

// Extracts the items(components) from the AEM container and adds to the array of components (flatComponentsArray)
const processContainerData = (_containerName: string, containerData: any) => {
  const containerItems = containerData[':items'];
  const containerItemsOrder = containerData[':itemsOrder'];

  containerItemsOrder.forEach((containerItemName: string) => {
    const containerItem = containerItems[containerItemName];
    const containerItemType = stripPath(containerItem[':type']) || 'unknown';
    const containerItemColumnClassNames =
      containerData.columnClassNames[containerItemName];

    if (containerItemType === 'container') {
      processContainerData(containerItemName, containerItem);
    } else {
      // Item is a (storybook) component
      // check for supported types from the lookup file
      if (
        !containerItem.hasOwnProperty('vaaData') ||
        isObjectEmpty(containerItem.vaaData)
      )
        return;

      //! This is where the props are processed
      const vr: ValidationResult = validateComponentProps(
        containerItem.vaaData,
        containerItemType
      );

      if (vr.result === ValidationOutcomes.UNKNOWN) {
        console.error(
          `${containerItem.id} has unkown validation status and will not be rendered.`,
          vr.messages
        );
        return;
      }

      if (vr.result === ValidationOutcomes.FAILURE) {
        console.error(
          `${containerItem.id} has failed validation and will not be rendered.`,
          vr.messages
        );
        return;
      }

      if (vr.result === ValidationOutcomes.WARNING) {
        // console.warn(
        //   `${containerItem.id} has validation warnings and will be rendered.`,
        //   vr.messages
        // );
      }

      const componentData = {
        isContainer: false,
        name: containerItemName,
        componentType: containerItemType,
        componentProps: vr.componentProps,
        gridInfo: getColumnClassInfo(containerItemColumnClassNames),
      };

      flatComponentsArray.push(componentData);
    }
  });
};

export const processData = async (aemServerData: any) => {
  flatComponentsArray = [];
  cleanHeadlessData(aemServerData);

  const rootItems = aemServerData[':items'].root[':items'];
  const rootItemsOrder = aemServerData[':items'].root[':itemsOrder'];

  rootItemsOrder.forEach((rootItemName: string) => {
    const rootItem = rootItems[rootItemName];
    const rootItemType = stripPath(rootItem[':type']);
    if ('container' === rootItemType) {
      if (flatComponentsArray.length > 0)
        flatComponentsArray.push({
          isContainer: false,
          name: `spacer-${Date.now()}`,
          componentType: 'spacer',
          componentProps: null,
          gridInfo: { width: 12, offset: 0 },
        });
      processContainerData(rootItemName, rootItem);
    }
  });

  if (
    flatComponentsArray.length > 0 &&
    flatComponentsArray[flatComponentsArray.length - 1].componentType !==
      'spacer'
  )
    // last spacer for the page
    flatComponentsArray.push({
      isContainer: false,
      name: `spacer-${Date.now()}`,
      componentType: 'spacer',
      componentProps: null,
      gridInfo: { width: 12, offset: 0 },
    });

  // console.log('Flattened Components', flatComponentsArray);
  return [
    {
      isContainer: true,
      name: 'AEM Root Container',
      columnCount: 12,
      gridInfo: { width: 12, offset: 0 },
      components: flatComponentsArray,
    },
  ];
};
