import { ValidationResult, ValidationOutcomes } from '.';

const ValidateTextLockupComponentProps = (props: any): ValidationResult => {
  let vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
  };

  vr.componentProps = props;
  vr.result = ValidationOutcomes.SUCCESS;
  return vr;
};

export default ValidateTextLockupComponentProps;
