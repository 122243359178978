import { getStringBetween } from '..';
import { Logger } from './logging';

export async function priceGrabPageContent(pageContent: any[]) {
  let pageJSON = JSON.stringify(pageContent);

  const priceGrabDefinitions = pageJSON.match(/\${.*?}/g);

  if (!priceGrabDefinitions || priceGrabDefinitions.length === 0)
    return pageContent;

  const promises: Promise<any>[] = [];

  priceGrabDefinitions.forEach((priceGrabDefinition) =>
    promises.push(
      fetchSpecialOffers(
        getStringBetween_pg(cleanupDefinition(priceGrabDefinition))
      )
    )
  );

  if (promises.length === 0) return JSON.parse(pageJSON);

  const values = await Promise.all(promises);

  priceGrabDefinitions.forEach((priceGrabDefinition, index) => {
    const resolvedValue = values[index].values.minPricePerPerson;

    if (resolvedValue === 'error') {
      // we received an error
      pageJSON = pageJSON.replace(priceGrabDefinition, '');
    } else {
      // we have received a valid response
      const priceTag = priceGrabDefinition
        .replace(
          '__priceGrabber?' + getStringBetween_pg(priceGrabDefinition) + '__',
          resolvedValue
        )
        .replace('${', '')
        .replace('}', '');
      pageJSON = pageJSON.replace(priceGrabDefinition, priceTag);
    }
  });

  return JSON.parse(pageJSON);
}

async function fetchSpecialOffers(queryString: string) {
  if (!process.env.NEXT_PUBLIC_API_ENDPOINT) {
    return { values: { minPricePerPerson: 'error' } };
  }

  if (queryString.charAt(0) === '?') queryString = queryString.substring(1);

  //! PLEASE REMOVE THIS LINE WHEN THE TEST APIS ARE READY
  const specialOffersApiBasepath = 'http://vsolauth.grn.virginholidays.co.uk';

  const specialOffersApiEndpoint =
    specialOffersApiBasepath +
    (process.env.NEXT_PUBLIC_SPECIAL_OFFERS_API_PATH ?? '/special-offers-api') +
    '/special-offer/search/lowest/minPricePerPerson?';
  const finalEndpoint = specialOffersApiEndpoint + queryString;
  let data: any = {};

  try {
    const res = await fetch(finalEndpoint);

    if (res.status === 200) {
      data = await res.json();
      // Round price to nearest whole number
      data.values.minPricePerPerson = roundPrice(data.values.minPricePerPerson);
    } else {
      if (res.status === 404) {
        // API returns 404 if no results found (e.g. parent=a_wrong_parent_id)
        new Logger().error(
          `Special offers API returns 404 - (maybe parent parameter is not found): ${finalEndpoint}`
        );
      } else {
        new Logger().error(
          `Error fetching special offers. Error (${res.status}): ${finalEndpoint}`
        );
      }
      data = { values: { minPricePerPerson: 'error' } };
    }

    return data;
  } catch (e) {
    new Logger().error(
      `Error fetching special offers from ${finalEndpoint}\r\n${e}`
    );
    data = { values: { minPricePerPerson: 'error' } };
  }
  return data;
}

const getStringBetween_pg = (
  inputString: string,
  startString: string = '__priceGrabber?',
  endString: string = '__'
) =>
  getStringBetween(inputString, startString, endString).replace('||true', '');

const roundPrice = (originalPrice: number): number =>
  originalPrice % 1 < 0.5
    ? Math.floor(originalPrice)
    : Math.ceil(originalPrice);

const cleanupDefinition = (definition: string) =>
  definition.replace(/&amp;/g, '&');
