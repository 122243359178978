import { ValidationResult, ValidationOutcomes } from '.';
import ValidateGalleryCardComponentProps from './gallery-card-component-validator';

const ValidateDestinationCarouselComponentProps = (props: any): ValidationResult => {
  let vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
  };

  if (
    !props.hasOwnProperty('destinations') ||
    props.destinations.length === 0
  ) {
    vr.messages.push(
      'Destination Carousel does not have (valid) destinations (Gallery Cards)'
    );
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  // carousel has children cards, check if they are valid
  const destinations: any = [];
  props.destinations.forEach((destination: any, index: number) => {
    const destinationValidationResult = ValidateGalleryCardComponentProps(destination);

    if (destinationValidationResult.result === ValidationOutcomes.FAILURE) {
      // single card validation failed, destination carousel may be valid
      vr.result = ValidationOutcomes.WARNING;
      vr.messages = vr.messages.concat(destinationValidationResult.messages);
      vr.messages.push(
        `Invalid gallery card data at destination[${index}], gallery card ignored`
      );
    } else if (
      destinationValidationResult.result === ValidationOutcomes.WARNING
    ) {
      vr.result = ValidationOutcomes.WARNING;
      vr.messages = vr.messages.concat(destinationValidationResult.messages);
      vr.messages.push(
        `Warning bad gallery card data at destination[${index}], using the gallery card`
      );
      destinations.push(destinationValidationResult.componentProps);
    } else {
      // card is valid
      if (vr.result !== ValidationOutcomes.WARNING)
        vr.result = ValidationOutcomes.SUCCESS;
      destinations.push(destinationValidationResult.componentProps);
    }
  });

  if (destinations.length === 0) {
    // no valid cards found, container is invalid
    vr.result = ValidationOutcomes.FAILURE;
    vr.messages.push(
      'No valid gallery cards found, destination carousel is invalid'
    );
  } else {
    // at least one valid card found, destination carousel is valid
    vr.componentProps = { destinations };
  }

  return vr;
};

export default ValidateDestinationCarouselComponentProps;
