import { convertToAbsoluteImageUrl, convertToAbsoluteLinkUrl } from 'src/utils';
import { ValidationResult, ValidationOutcomes } from '.';
import { isObjectEmpty } from '../utils/aem-utils/utils';

const validateArticleCardProps = (item: any): boolean => {
  return (
    item &&
    typeof item.imagePath === 'string' &&
    typeof item.lastUpdated === 'string' &&
    typeof item.path === 'string' &&
    typeof item.title === 'string' &&
    (item.className === undefined || typeof item.className === 'string')
  );
};

const validateAlertCardProps = (item: any): boolean => {
  return (
    item &&
    typeof item.lastUpdated === 'string' &&
    typeof item.path === 'string' &&
    typeof item.title === 'string' &&
    (item.className === undefined || typeof item.className === 'string')
  );
};

const ValidateArticlesListComponentProps = (props: any): ValidationResult => {
  const vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
    componentProps: null,
  };

  if (isObjectEmpty(props)) {
    vr.messages.push('Props are empty');
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  const { articleItems, displayAs, layout } = props;

  if (!displayAs || (displayAs !== 'notification' && displayAs !== 'default')) {
    vr.messages.push('Invalid or missing displayAs');
    vr.result = ValidationOutcomes.FAILURE;
  }

  if (layout && layout !== 'grid' && layout !== 'mosaic') {
    vr.messages.push('Invalid layout');
    vr.result = ValidationOutcomes.FAILURE;
  }

  if (articleItems && Array.isArray(articleItems)) {
    for (const item of articleItems) {
      if (displayAs === 'default' && !validateArticleCardProps(item)) {
        vr.messages.push('Invalid article item');
        vr.result = ValidationOutcomes.FAILURE;
        break;
      }
      if (displayAs === 'notification' && !validateAlertCardProps(item)) {
        vr.messages.push('Invalid notification item');
        vr.result = ValidationOutcomes.FAILURE;
        break;
      }
      item.imagePath = convertToAbsoluteImageUrl(item.imagePath);
      item.path = convertToAbsoluteLinkUrl(item.path, '.html');
    }
  } else if (!articleItems) {
    vr.messages.push('Invalid or missing articleItems');
    vr.result = ValidationOutcomes.FAILURE;
  }

  if (vr.messages.length === 0) {
    vr.result = ValidationOutcomes.SUCCESS;
    vr.componentProps = props;
  }

  return vr;
};

export default ValidateArticlesListComponentProps;
