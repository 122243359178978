import { objectHasFields } from 'src/utils/aem-utils/utils';
import { ValidationResult, ValidationOutcomes } from '.';
import ValidateAccordionItemProps from './accordion-item-component-validator';

export default function ValidateTabsProps(
  tabsProps: any
): ValidationResult {
  const vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
    componentProps: null,
  };

  if (
    !objectHasFields(tabsProps, ['items']) ||
    !Array.isArray(tabsProps.items) ||
    tabsProps.items.length === 0
  ) {
    vr.messages.push('Tabs is missing Items[]');
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }


  vr.componentProps = tabsProps;
  vr.result = ValidationOutcomes.SUCCESS;
  return vr;
}
