import { objectHasFields } from 'src/utils/aem-utils/utils';
import { ValidationOutcomes, ValidationResult } from '.';

const ValidateTableCardProps = (props: any): ValidationResult => {
  let vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
  };

  vr.result = ValidationOutcomes.SUCCESS;
  vr.componentProps = props;
  return vr;
};

export default ValidateTableCardProps;
