import { objectHasFields } from 'src/utils/aem-utils/utils';
import { ValidationOutcomes, ValidationResult } from '.';

const isIconPanelPanelPropValid = (
  iconPanelPanelProps: any
): ValidationResult => {
  const vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
    componentProps: null,
  };

  if (!objectHasFields(iconPanelPanelProps, ['text'])) {
    vr.result = ValidationOutcomes.FAILURE;
    vr.messages.push('IconPanel Panel is missing text');
    return vr;
  }

  vr.result = ValidationOutcomes.SUCCESS;
  vr.componentProps = iconPanelPanelProps;
  return vr;
};

export default isIconPanelPanelPropValid;
