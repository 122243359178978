import { ReactNode } from 'react';
import styles from './container.component.module.less';
import classNames from 'classnames';

interface ContainerComponentProps {
  children: ReactNode | ReactNode[];
  disablePadding?: boolean;
}

const ContainerComponent = ({ children, disablePadding = false }: ContainerComponentProps) => {
  if (!children) {
    return null;
  }
  return <div className={
    classNames(
      styles['container'],
      {
        [styles['padding-disabled']]: disablePadding
      }
    )
  }>{children}</div>;
};

export default ContainerComponent;
