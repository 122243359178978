import { objectHasFields } from 'src/utils/aem-utils/utils';
import { ValidationOutcomes, ValidationResult } from '.';

const ValidateGlobalAlertBannerComponentProps = (
  props: any
): ValidationResult => {
  let vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
  };

  if (!objectHasFields(props, ['text'])) {
    vr.messages.push('Global Alert Banner is missing text');
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  vr.componentProps = props;
  vr.result = ValidationOutcomes.SUCCESS;
  return vr;
};

export default ValidateGlobalAlertBannerComponentProps;
