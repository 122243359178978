import React, { useMemo } from 'react';
import HTMLRenderer from '../html-renderer/html-renderer.component';
import SearchPanelComponent from '../search-panel/search-panel.component';
import { Element } from '../html-renderer/html-renderer.component';
import { isPreviewMode, SearchPanelConfig } from 'src/utils/ssr-helper';

interface PageESIWrapperComponentProps {
  children: React.ReactNode;
  searchPanelConfig?: SearchPanelConfig;
  isTravelPlus?: boolean;
}

function setQueryParameters(type: 'HEADER' | 'FOOTER', isTravelPlus: boolean) {
  const brand =
    process.env.NEXT_PUBLIC_BRAND_NAME?.toLowerCase() === 'vaa'
      ? '?brand=vaa'
      : '?brand=vhols';
  const preview = isPreviewMode ? '&amp;preview=true' : '';
  const travelPlus = isTravelPlus ? '&amp;tp=true&amp;ls=false' : '';

  if (type === 'HEADER') {
    return `${brand}${preview}${travelPlus}`;
  } else if (type === 'FOOTER') {
    return `${brand}${preview}`;
  } else {
    throw new Error('Invalid type on page-esi-wrapper.component.tsx');
  }
}

const generateESIInclude = (path: string, params: string): string =>
  `<!--esi <esi:include src="${path}${params}"></esi:include> -->`;

const generateSearchQueryParams = (
  destination: string | null,
  bookingType: string | null
): string => {
  return [
    destination
      ? `&location=${destination.trim().replace(/ /g, '-').toLowerCase()}`
      : '',
    bookingType ? `&bookingType=${bookingType.trim().toLowerCase()}` : '',
  ]
    .filter(Boolean)
    .join('');
};

const createDataLayer = (): string =>
  '<esi:include src="/analytics-data-layer/home-page/"></esi:include><esi:remove><!--# include virtual="/analytics-data-layer/home-page/" --></esi:remove>';

const createConfig = (
  headerParams: string,
  searchPath: string,
  footerParams: string,
  searchQueryParams: string,
  displayButton: boolean
) => {
  return {
    header: generateESIInclude(`/next-header-footer/header${headerParams}`, ''),
    search: {
      esi: generateESIInclude(searchPath, searchQueryParams),
      displayButton,
    },
    routeMap: {
      esi: generateESIInclude('/route-map/', ''),
    },
    footer: generateESIInclude(`/next-header-footer/footer${footerParams}`, ''),
    datalayer: createDataLayer(),
  };
};

export const ESIData = (
  isTravelPlus: boolean,
  destination: string | null = null,
  bookingType: string | null = null
) => {
  const headerParams = setQueryParameters('HEADER', isTravelPlus);
  const footerParams = setQueryParameters('FOOTER', isTravelPlus);
  const searchQueryParams = generateSearchQueryParams(destination, bookingType);

  return {
    VAA: createConfig(
      headerParams,
      `/search-experience/?brand=VAA&state:open=true&state:closable=false&timeStamp=${Date.now()}`,
      footerParams,
      searchQueryParams,
      false
    ),
    TP: createConfig(
      headerParams,
      `/server-rendered-search-panel/?brand=300&timeStamp=${Date.now()}`,
      footerParams,
      searchQueryParams,
      true
    ),
    VHOLS: createConfig(
      headerParams,
      `/server-rendered-search-panel/summary?brand=205&timeStamp=${Date.now()}`,
      footerParams,
      searchQueryParams,
      true
    ),
  };
};

function PageESIWrapperComponent({
  children,
  isTravelPlus = false,
  searchPanelConfig,
}: PageESIWrapperComponentProps) {
  const ESIObject = useMemo(() => {
    const esiData = ESIData(
      isTravelPlus,
      searchPanelConfig?.destination,
      searchPanelConfig?.bookingType
    );
    if (process.env.NEXT_PUBLIC_BRAND_NAME?.toLowerCase() === 'vaa') {
      if (isTravelPlus) {
        return esiData.TP;
      }
      return esiData.VAA;
    } else {
      return esiData.VHOLS;
    }
  }, [
    isTravelPlus,
    searchPanelConfig?.bookingType,
    searchPanelConfig?.destination,
  ]);

  return (
    <>
      <HTMLRenderer id="next_header" htmlString={ESIObject.header} />
      <SearchPanelComponent
        isVisible={!searchPanelConfig?.hideSearchPanel}
        ESIString={ESIObject.search.esi}
        displayButton={ESIObject.search.displayButton}
      />
      {children}
      <HTMLRenderer
        id="next_footer"
        htmlString={ESIObject.footer}
        element={Element.DIV}
      />
      <HTMLRenderer id="next_datalayer" htmlString={ESIObject.datalayer} />
    </>
  );
}

export default PageESIWrapperComponent;
