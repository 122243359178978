import { objectHasFields } from 'src/utils/aem-utils/utils';
import { ValidationOutcomes, ValidationResult } from '.';

const ValidateCountdownBannerComponentProps = (
  props: any
): ValidationResult => {
  let vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
  };

  if (
    !objectHasFields(props, [
      'startTime',
      'endTime',
      'bodyText',
      'bodySubtext',
      'ctaUrl',
    ])
  ) {
    vr.messages.push(
      'Countdown banner is missing either startTime endTime bodyText bodySubtext ctaUrl'
    );
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  if (props.endTime <= Date.now()) {
    vr.messages.push(
      "Countdown banner's endTime is in the past, so it will not be displayed."
    );
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  vr.result = ValidationOutcomes.SUCCESS;
  vr.componentProps = props;
  return vr;
};

export default ValidateCountdownBannerComponentProps;
