import { convertToAbsoluteLinkUrl } from 'src/utils';
import { ValidationResult, ValidationOutcomes } from '.';
import { isObjectEmpty } from '../utils/aem-utils/utils';

const ValidateBreadcrumbsProps = (props: any): ValidationResult => {
  const vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
    componentProps: null,
  };

  if (isObjectEmpty(props)) {
    vr.messages.push('Props are empty');
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  const { links } = props;

  if (links && links.length === 0) {
    vr.messages.push('Invalid links');
    vr.result = ValidationOutcomes.FAILURE;
  }

  if (vr.messages.length === 0) {
    vr.result = ValidationOutcomes.SUCCESS;
    vr.componentProps = props;
  }

  links.forEach((item: any) => {
    item.url = convertToAbsoluteLinkUrl(item.url, '.html');
  });

  return vr;
};

export default ValidateBreadcrumbsProps;
