import { Logger } from '../services/logging';


export function getHeaders() {
    return {
        'Content-Type': 'application/json'
    };
}

export const  handleResponse = async (response: Response) => {
    const isServerside = typeof window === 'undefined';
    if (!response.ok) {
        if(isServerside) {
            new Logger().info(`Error from API response: ${response.statusText}`);
        }
        return Promise.reject(response.statusText);
    }
    const responseText = await response.text();
    const data = responseText && JSON.parse(responseText);

    if (!data) {
        if (isServerside) {
            new Logger().info(`Error from API response: ${response.statusText}`);
        }
        return Promise.reject(response.statusText);
    }
    return data;
};