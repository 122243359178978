import { checkAndAddProps } from 'src/utils';
import { ValidationResult, ValidationOutcomes } from '.';
import ValidateContentCardComponentProps from './content-card-component-validator';
import ValidateGalleryCardComponentProps from './gallery-card-component-validator';
import ValidateTableCardProps from './table-card-component-validator';

const ValidateCarouselComponentProps = (props: any): ValidationResult => {
  let vr: ValidationResult = {
    result: ValidationOutcomes.UNKNOWN,
    messages: [],
  };

  if (!props.hasOwnProperty('children') || props.children.length === 0) {
    vr.messages.push('Carousel does not have (valid) children');
    vr.result = ValidationOutcomes.FAILURE;
    return vr;
  }

  // carousel has children cards, check if they are valid
  const cards: any = [];
  props.children.forEach((child: any, index: number) => {
    if (
      !child.hasOwnProperty('sling:resourceType') ||
      !['content-card', 'destination-card', 'table-card'].includes(
        child['sling:resourceType'].split('/').pop()
      )
    ) {
      vr.result = ValidationOutcomes.WARNING;
      vr.messages.push(
        `Carousel card at index ${index} is missing sling:resourceType, invalid type`
      );
      return;
    }

    let cardValidationResult: ValidationResult = {
      result: ValidationOutcomes.UNKNOWN,
      messages: [],
    };

    // first get card type
    const cardType = child['sling:resourceType'].split('/').pop();
    switch (cardType) {
      case 'content-card':
        cardValidationResult = ValidateContentCardComponentProps(child);
        break;
      case 'destination-card':
        cardValidationResult = ValidateGalleryCardComponentProps(child);
        break;
      case 'table-card':
        cardValidationResult = ValidateTableCardProps(child);
        break;
      default:
        break;
    }

    if (cardValidationResult.result === ValidationOutcomes.FAILURE) {
      // single card validation failed, conatiner may be valid
      vr.result = ValidationOutcomes.WARNING;
      vr.messages = vr.messages.concat(cardValidationResult.messages);
      vr.messages.push(
        `Invalid ${cardType} card data at item${index}, card deleted`
      );
    } else if (cardValidationResult.result === ValidationOutcomes.WARNING) {
      // single card validation failed, container may be valid
      vr.result = ValidationOutcomes.WARNING;
      vr.messages = vr.messages.concat(cardValidationResult.messages);
      vr.messages.push(
        `Warning ${cardType} card data at item${index}, using the card`
      );
      cards.push({ cardType, ...cardValidationResult.componentProps });
    } else {
      // card is valid
      if (vr.result !== ValidationOutcomes.WARNING)
        vr.result = ValidationOutcomes.SUCCESS;

      cards.push({ cardType, ...cardValidationResult.componentProps });
    }
  });

  if (cards.length === 0) {
    // no valid cards found, container is invalid
    vr.result = ValidationOutcomes.FAILURE;
    vr.messages.push('No valid cards found, carousel is invalid');
  } else {
    // at least one valid card found, carousel is valid
    vr.componentProps = {
      children: cards,
    };

    checkAndAddProps(props, vr.componentProps, [
      'configuration',
      'animateIn',
      'controlsConfig',
      'extendedXlLayout'
    ]);
  }

  return vr;
};

export default ValidateCarouselComponentProps;
