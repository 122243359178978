import getIcon from 'src/components/icons';
import { getAEMBasepath } from './basepaths';
import {
  ENVIRONMENTS,
  currentBrand,
  isPreviewMode,
  isTravelPlusHomepage,
  currentEnvironment,
} from './ssr-helper';

const absoluteUrlLookup: any = {
  helpCentre1: 'help',
  helpCentre: 'flywith',
  'vaa-home': 'vaahome',
  vhols: 'vholshome',
  corporate: 'corporate',
};

export function convertToAbsoluteImageUrl(imageUrl: string) {
  if (imageUrl.startsWith('http')) return imageUrl;

  if (!imageUrl.startsWith('/')) imageUrl = '/' + imageUrl;

  const imageBasePath = getAEMBasepath().main;

  const absoluteImageUrl = imageBasePath + imageUrl;

  return absoluteImageUrl;
}

function handleURLForStageAndProd({ lookupKey }: { lookupKey: string }) {
  if (lookupKey.includes('helpCentre')) {
    const envPrefix =
      currentEnvironment.toLowerCase() === ENVIRONMENTS.UAT ? 'stg-' : '';
    return `https://${envPrefix}${absoluteUrlLookup[lookupKey]}.virginatlantic.com`;
  }
  return getAEMBasepath().main;
}

export function convertToAbsoluteLinkUrl(
  linkProperty: string,
  suffix: string | null = null
) {
  if (linkProperty.startsWith('http')) return linkProperty;

  if (!linkProperty.startsWith('/')) linkProperty = '/' + linkProperty;

  const linkSubUrls = linkProperty.split('/');
  const lookupKey = linkSubUrls[2];

  if (!lookupKey) {
    return linkProperty;
  }

  let targetDomain;

  try {
    if (currentEnvironment.includes('test')) {
      targetDomain = `https://${currentEnvironment.toLowerCase()}-${absoluteUrlLookup[
        lookupKey
      ].toLowerCase()}.webdev.vholsinternal.co.uk`;
    } else if (
      currentEnvironment === ENVIRONMENTS.UAT ||
      currentEnvironment === ENVIRONMENTS.PROD
    ) {
      targetDomain = handleURLForStageAndProd({
        lookupKey,
      });
    } else {
      // Wrong environment, return the link as is
      return linkProperty;
    }

    const targetUrl = linkSubUrls.slice(3).join('/');
    const absoluteLink = targetDomain + '/' + targetUrl;

    if (suffix) {
      return `${absoluteLink}.html`;
    }

    return absoluteLink;
  } catch (e) {
    throw new Error('Error in convertToAbsoluteLinkUrl function');
  }
}

export const checkAndAddProps = (
  sourceProps: any,
  targetProps: any,
  propNames: string[]
) =>
  propNames.forEach(
    (propName) =>
      sourceProps.hasOwnProperty(propName) &&
      (targetProps[propName] = sourceProps[propName])
  );

export function getStringBetween(
  inputString: string,
  startString: string,
  endString: string
) {
  const start = inputString.indexOf(startString) + startString.length;
  const end = inputString.indexOf(endString, start);
  let stringBetween = '';
  try {
    stringBetween = inputString.substring(start, end);
  } catch (e) {
    console.error('Error in getStringBetween function', e, inputString);
  }

  return stringBetween;
}

export function replaceStringBetween(
  inputString: string,
  startString: string,
  endString: string,
  replacementString: string
) {
  const start = inputString.indexOf(startString);
  const end = inputString.lastIndexOf(endString);

  let replacedString = '';
  try {
    const stringBetween = inputString.substring(start, end + endString.length);
    replacedString = inputString.replace(stringBetween, replacementString);
  } catch (e) {
    console.error('Error in replaceStringBetween function', e, inputString);
  }

  return replacedString;
}

export function getTimestamp() {
  const timeNow = new Date();
  return `${
    timeNow.getHours() < 10 ? `0${timeNow.getHours()}` : `${timeNow.getHours()}`
  }:\
${
  timeNow.getMinutes() < 10
    ? `0${timeNow.getMinutes()}`
    : `${timeNow.getMinutes()}`
}:\
${
  timeNow.getSeconds() < 10
    ? `0${timeNow.getSeconds()}`
    : `${timeNow.getSeconds()}`
}:\
${timeNow.getMilliseconds()}`;
}

export function tLog(...args: any[]) {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
    console.log('-->', getTimestamp(), ...args);
}

export function checkCardBadge(props: any) {
  if (props.hasOwnProperty('badge') && props.badge) {
    if (props.badge.hasOwnProperty('icon') && props.badge.icon) {
      const C = getIcon(props.badge.icon);
      props.badge.iconChild = <C />;
    }
    if (props.badge.hasOwnProperty('hideLabel') && props.badge.hideLabel) {
      delete props.badge.hideLabel;
      delete props.badge.label;
    }
    if (props.badge.hasOwnProperty('skin') && props.badge.skin) {
      const badgeStatuses = [
        'general',
        'neutral',
        'promotional',
        'success',
        'error',
        'warning',
      ];
      if (badgeStatuses.includes(props.badge.skin.toLowerCase())) {
        props.badge.status = props.badge.skin;
      } else {
        props.badge.status = 'general';
      }
      delete props.badge.skin;
    }
  }
}

export const scrollToSection = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
    window.location.hash = `#${id}`;
  }
};

export function getContentPath(route: string, locale: string) {
  let pageRegion = locale.split('-')[1].toLowerCase(); // default to gb
  const pageLanguage = locale.split('-')[0].toLowerCase(); // default to en
  const basePaths = getAEMBasepath();
  const basePath = isPreviewMode ? basePaths.preview : basePaths.main;
  const isLocal = currentEnvironment === ENVIRONMENTS.LOCAL;
  const brandPath = currentBrand === 'VAA' ? 'vaa-home' : 'vhols';

  let path = '';

  if (route === '/') {
    if (isTravelPlusHomepage) {
      if (pageRegion.toLowerCase() === 'us') {
        // throw new Error('404 - Content not found');
        pageRegion = 'gb';
      }
      path = `${pageRegion}/travelplus.model.json`;
    } else {
      if (isLocal) {
        path = `content/${brandPath}/${pageRegion}/${pageLanguage}.model.json`;
      } else {
        path = `${pageRegion}/${pageLanguage}.model.json`;
      }
    }
  } else {
    // That means it's preview with brandPath query param
    // console.log('route: ', route);
    if (isPreviewMode) {
      path = route;
    } else {
      console.log('============> route: ', route);
      throw new Error('Route not found');
    }
  }

  const backendUrl = basePath + path;

  return backendUrl;
}
