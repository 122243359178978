import { getEndpoint } from '../basepaths';
import { getHeaders, handleResponse } from '../api-utils/utils';
import { JSONObject } from '../api-utils/types';
import { Logger } from './logging';

async function getById(locationId: string): Promise<JSONObject> {
    const requestOptions = {
        method: 'GET',
        headers: getHeaders(),
    };
    try {
        const response = await fetch(
            `${getEndpoint().LOCATIONS_API}/search/location?brand=205&locationId=${locationId}&bookingType=1`,
            requestOptions
        );
        return handleResponse(response);
    } catch (error) {
        const isServerside = typeof window === 'undefined';
        if (isServerside){
            new Logger().error(`Error fetching location data: ${error}`);
        }
        return Promise.reject(error);
    }
};

export const locationsService = {
    getById
};

